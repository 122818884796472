<template>
  <div class="drag_scale_cpms">
    <div class="left" @mousewheel.prevent="rollImg">
      <img :src="src" alt="" class="img" ref="imgDiv" @mousedown="move" />
    </div>
    <div class="tips">滚动鼠标可以放大缩小查看发票</div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      default: ''
    }
  },
  data() {
    return {};
  },
  methods: {
    // 拖动图片
    move(e) {
      e.preventDefault();
      // 获取元素
      var left = document.querySelector('.left');
      var img = document.querySelector('.img');
      var x = e.pageX - img.offsetLeft;
      var y = e.pageY - img.offsetTop;
      // 添加鼠标移动事件
      left.addEventListener('mousemove', move);
      function move(e) {
        img.style.left = e.pageX - x + 'px';
        img.style.top = e.pageY - y + 'px';
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img.addEventListener('mouseup', function () {
        left.removeEventListener('mousemove', move);
      });
      // 鼠标离开父级元素，把事件移除
      left.addEventListener('mouseout', function () {
        left.removeEventListener('mousemove', move);
      });
    },
    // 缩放图片
    rollImg(e) {
      /* 获取当前页面的缩放比 若未设置zoom缩放比，则为默认100%，即1，原图大小 */

      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100;
      /* event.wheelDelta 获取滚轮滚动值并将滚动值叠加给缩放比zoom wheelDelta统一为±120，其中正数表示为向上滚动，负数表示向下滚动 */

      zoom += e.wheelDelta / 12;
      /* 最小范围 和 最大范围 的图片缩放尺度 */

      if (zoom >= 80 && zoom < 500) {
        this.$refs.imgDiv.style.zoom = zoom + '%';
      }
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
.drag_scale_cpms {
  height: 100%;
  margin-bottom: 40px;
  text-align: left;
  .left {
    position: relative;
    width: 755px;
    height: 440px;
    left: calc(50% - 378px);
    background-color: #fff;
    overflow: hidden;
    border: 1px dashed #dcdfe6;
    z-index: 1;
    display: inline-block;
    margin: 0 auto;
  }
  .img {
    position: absolute;
    top: 5px;
    left: 7px;
    width: 755px;
    max-width: 744px;
    max-height: 440px;
    cursor: move;
  }
  .tips {
    font-size: 13px;
    color: #999;
    text-align: center;
  }
}
</style>
